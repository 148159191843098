@import '../../Color.scss';

.my-line-chart-container {
  // padding: 15px;
  .my-line-chart-labels {
    padding: 5px;
    cursor: pointer;
  }
  .selected-index {
    color: $primary-color !important;
    background: rgba(136, 112, 230, 0.06) !important;
    border-radius: 10px;
    padding: 5px;
  }
}
