@import '../../../Color.scss';

.copy-row-wrapper {
  border-radius: $border-radius;
  border: 1px solid $primary-color;
  height: 40px;
  padding: 0 20px;
}

.number-bg {
  display: flex;
  background-color: $primary-color;
  border-radius: 50px !important;
  color: $white-color !important;
  height: 20px !important;
  width: 20px !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
}
