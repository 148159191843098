@import '../../Color.scss';

.table-row {
  height: 50px;
  vertical-align: center;
  &.ant-table-row-selected {
    background-color: $gray-light-color-transparent50;
  }
  .ant-table-selection-column {
    vertical-align: middle;
  }
  .ant-table-cell {
    .ant-form-item {
      margin-bottom: 0px;
      .ant-form-item-explain-error {
        display: none;
      }
    }
    span {
      color: $bluish-color;
    }
  }
  &.selected {
    background: linear-gradient(
      95.7deg,
      #ffcc9a -2.46%,
      #ff879a 51.05%,
      #e78dff 102.46%
    );

    .ant-table-cell {
      span {
        color: $white-color !important;
      }
    }
    .ant-table-cell {
      .ant-form-item {
        span {
          color: $bluish-color !important;
        }
      }
    }
    .ant-switch {
      background: $white-color !important;
    }
    .ant-switch-checked {
      background: $primary-color-lighter !important;
    }
    .ant-btn {
      background: $white-color !important;
      span {
        color: $primary-color !important;
      }
    }
    .ant-table-selection-column {
      vertical-align: top !important;
      padding-top: 20px !important;
      //! change later ^
    }
  }
}

.table-row:hover > td {
  background-color: $gray-light-color-transparent50 !important;
  .ant-btn {
    background: $white-color !important;
    span {
      color: $primary-color !important;
    }
  }
}

.ant-table-wrapper {
  .ant-table-container {
    table > thead > tr:first-child > *:last-child {
      border-start-end-radius: 0;
    }
  }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    &.active {
      color: $bluish-color;
    }
  }
  td {
    &.ant-table-column-sort {
      background-color: transparent;
      span {
        font-size: 14px;
        font-weight: 700 !important;
        color: $primary-color;
      }
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      &.ant-table-selection-column {
        &::before {
          position: absolute;
          top: 50%;
          inset-inline-end: 0;
          width: 1px;
          height: 1.6em;
          background-color: #f0f0f0;
          transform: translateY(-50%);
          transition: background-color 0.2s;
          content: '';
        }
      }
    }
    th {
      &.ant-table-column-sort {
        background-color: #fafafa;
        .ant-table-column-title {
          color: #2e3e5f;
        }
      }
    }
  }
}

.InfiniteScrollTable-table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden !important;
  overflow-x: scroll;
  .ant-table-column-title {
    color: #a7aab5;
    font-size: 14px;
    font-weight: 500;
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    border: 1px solid $secondary-text !important;
    border-radius: 6px !important;
    &:after {
      width: 10px !important;
      height: 10px !important;
      border-radius: 2px;
    }
  }
}

.ant-table-cell {
  &.ant-table-row-expand-icon-cell {
    width: 1px;
    padding: 0 !important;
  }
}
