@import '../../../Color.scss';

.transaction-number-input {
  .input-number {
    border: none !important;
    text-align: center;
    .ant-input-number-input {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      // color: $secondary-color;
      height: 40px;
    }
    &:hover {
      border: none !important;
    }
  }
}

.transactions-segment-form {
  .ant-segmented {
    width: 100%;
    .ant-segmented-group {
      width: 100%;
      justify-content: space-between;
      .ant-segmented-item-label {
        padding: 0;
      }
      .transaction-segment-item {
        width: 100%;
        display: flex;
        padding: 0 11px;
        border-radius: 20px;
        justify-content: center;
      }
      label {
        transition: none;
        width: 33.3%;
        text-align: center;
      }
    }
  }
}

.switch-row {
  .ant-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .ant-col {
      width: auto;
      flex-grow: inherit;
      display: flex;
    }
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &.disableMargin {
    margin-bottom: 0px;
  }
}

.transaction_edit_modal {
  .ant-modal-content {
    background-color: $gray-light-color;
    .transaction-number-input {
      .input-number {
        background-color: transparent;
      }
    }
    .transaction_memo {
      textarea {
        min-height: 60px;
      }
    }
  }
  .ant-modal-title {
    background-color: $gray-light-color;
  }
}
