@import '../../Color.scss';

.react-code-input {
  margin-bottom: 30px;
}

.code-input {
  width: 100% !important;
  margin-bottom: 23px;
  display: flex;
  justify-content: center;
  input {
    border-radius: 6px !important;
    margin: 20px 6px 0px;
    @include bordered($very-light-gray, 1px);
    &:focus {
      @include bordered($primary-color, 1px);
      caret-color: $primary-color !important;
    }
  }
}
