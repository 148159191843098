@import '../../Color.scss';

.my-segment {
  width: 100%;
  border-radius: 20px;
  label {
    width: 50%;
  }
  .ant-segmented-item {
    border-radius: 20px;
    &:after {
      border-radius: 20px !important;
    }
  }
  &.white {
    background-color: $white-color;
  }

  .ant-segmented-thumb-motion-appear {
    border-radius: 100px;
  }
}
