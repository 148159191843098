@import '../../Color.scss';

.return-to-login {
  .login-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    p {
      margin-right: 5px;
      margin-bottom: 0;
      // color: $gray-color;
    }
  }
}
