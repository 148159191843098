@import '../../../Color.scss';

.budget-list-item-card {
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba($color: #3a354100, $alpha: 0.1);
  cursor: pointer;
  border-radius: $bigger-border-radius !important;
}

.budget-list-item-card:hover {
  .ant-typography {
    color: $primary-color !important;
  }
}

.budget-icon-wrapper {
  height: 50px;
  border-radius: 50px;
  width: 50px;
  background-color: #f4f6fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.budget-list-item-share-card {
  width: 200px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba($color: #3a354100, $alpha: 0.1);
  border-radius: $bigger-border-radius !important;
  cursor: pointer;
  text-align: center !important;
  .owner-name {
    font-weight: 500;
  }
  .owner-name-hover {
    font-weight: 500;
  }
}

.budget-list-item-share-card:hover {
  .budget-name {
    color: $primary-color !important;
  }
  .owner-name-hover {
    color: $primary-color !important;
  }
}
