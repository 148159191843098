@import '../../Color.scss';

.my_default_tag {
  border-radius: 12px;
  background-color: $primary-color-lighter10;
  padding: 8px 8px 8px 18px;
  font-size: 14px;
  font-weight: 500;
  color: $primary-color;
  width: auto;
  height: auto;
  line-height: 18px;
  margin-inline-end: 0px;
  align-items: center;
  gap: 10px;
  display: flex;
  border: none;
  &:hover {
    background-color: $primary-color-lighter10;
  }
}
