@import '../../Color.scss';

// .app-select {
//   span {
//     color: $secondary-color;
//   }
// }
// .ant-select-item-option-content {
//   color: $secondary-color;
// }
.ant-select-focused {
  box-shadow: none !important;
}
// .ant-select-disabled {
//   .ant-select-selector:hover {
//     border: 1px solid $very-light-gray !important;
//   }
// }
