@import '../../../Color.scss';

.transaction_list_table_header_wrapper {
  background-color: $white-color;
  border-bottom: 1px solid var(--Border, rgba(241, 241, 241, 1));
  .item {
    padding: 13px 18px;
    width: 150px;
    cursor: pointer;
    .title {
      color: $secondary-text;
      font-size: 14px;
      font-weight: 400;
    }
    &.selected {
      .title {
        color: $white-color;
      }
      .amount {
        color: $white-color;
      }
      .ant-checkbox-wrapper {
        &:hover {
          .ant-checkbox-inner {
            background-color: transparent;
            border-color: $white-color;
          }
        }
        .ant-checkbox-inner {
          background-color: transparent;
          border-color: $white-color;
        }
      }
    }
    .amount {
      font-weight: 500;
      font-size: 20px;
      color: $secondary-text;
    }
  }
}
