// @import '../../Color.scss';
// @import '../../ComponentStyle.scss';

// .my-modal {
//   border-radius: $block-border-radius;
//   // .ant-modal-content {
//   // }
//   // .modal-title {
//   //   color: $secondary-color;
//   // }
//   .ant-modal-title {
//     margin-bottom: 20px;
//   }

//   .ant-modal-footer {
//     display: flex;
//     justify-content: flex-end;
//   }
//   .modal-button {
//     display: flex;
//     justify-content: flex-end;
//   }
// }

.my-modal {
  //   .ant-modal-title {
  //     padding: 5px;
  //   }
  display: flex;
position: relative;

  .ant-modal-content {
    padding: 10px;
  }
}
