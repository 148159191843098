@import '../../Color.scss';

.custom_switch {
  background-color: $white-color;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 0 20px;
  .ant-form-item-row {
    width: 100%;
  }
}
