.my_default_range_picker {
  height: fit-content;
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 100px;
  &:hover {
    background-color: transparent;
    border-color: transparent;
    .my-button-secondary-filled {
      &.close_icon_padding {
        padding-right: 42px;
      }
    }
  }
  .ant-picker-clear {
    inset-inline-end: 8px;
  }

  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-range-separator {
    display: none;
  }
}
