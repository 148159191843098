.color-picker-wrapper {
  .ant-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    //   gap: 10px;
    justify-content: space-between;
    .ant-col {
      flex-grow: 0;
      width: auto;
      .ant-color-picker-color-block {
        //   width: 98%;
        //   height: 32px;
        //   border-radius: 10px;
      }
    }
  }
}
