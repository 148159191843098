.my_default_page_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .left,
  .right {
    width: 50%;
    &:empty {
      display: flex;
    }
  }
  .right {
    justify-content: flex-end;
  }
}
