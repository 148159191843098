@import '../../Color.scss';

// .my-month-picker {
//   width: 150px;
//   height: 30px;
//   padding: 0;
//   margin: 0;
//   align-items: center;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-around;
//   // box-shadow: 0px 2px 10px rgba(114, 135, 240, 0.1);
//   // border-radius: $input-button-border-radius;
//   padding: 0px 10px;
//   cursor: pointer;
//   .month-year {
//     font-size: 15px;
//     line-height: normal;
//   }
//   svg {
//     font-size: 15px !important;
//     // color: $primary-color;
//   }
//   // .arrow-wrapper {
//   //   width: 20px;
//   //   height: 100%;
//   //   display: flex;
//   //   align-items: center;
//   //   justify-content: center;
//   // }
//   .arrow-wrapper {
//     height: 20px !important;
//     width: 20px !important;
//     // background: #f4f6fa;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding-inline-end: 0px !important;
//     // margin-left: 5px;
//     // &:hover {
//     //   background-color: rgba(58, 53, 65, 0.04);
//     // }
//   }
//   .month-pocker-arrow-left {
//     transform: rotate(180deg);
//   }
// }

.my-month-datePicker {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
}

.month-picker-card {
  width: 150px;
  height: 35px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // padding: 0px 5px;
  .ant-card-body {
    padding: 0px;
  }
  .datePickerOpenText {
    cursor: pointer;
  }
}
