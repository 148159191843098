.my_default_page_layout {
  display: flex;
  padding: 15px 25px 15px 5px;
  gap: 15px;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  &.disable_bottom_padding {
    padding-bottom: 0;
  }
}
