@import '../../Color.scss';

.my-progress-bar {
  // margin-bottom: 0 !important;
  margin-inline-end: 0px;
  // .ant-progress-bg {
  //   background: $primary-color;
  // }
  // .ant-progress-outer {
  //   display: flex;
  //   align-items: center;
  // }
}

.my-progress-bar-selected {
  margin-bottom: 0 !important;
  margin-inline-end: 0px;
  .ant-progress-bg {
    background: $white-color !important;
  }
}
