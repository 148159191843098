.ant-form-item-optional {
  display: none !important;
}

.ant-form-item {
  &.loading {
    .ant-select {
      .ant-select-selector {
        span + span {
          color: transparent !important;
        }
      }
    }
  }
}
