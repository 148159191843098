@import '../../Color.scss';

.my-input {
  &.search {
    background-color: #ffffff;
    border-radius: 12px;
    width: 260px;
    height: 40px;
    padding: 10px 18px 10px 14px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid $border-color;
    &:focus,
    &:focus-within {
      box-shadow: none;
      border: 1px solid $focused-border-color !important;
    }
    .ant-input-prefix {
      margin-inline-end: 10px;
    }
    .ant-input {
      &:placeholder-shown {
        text-overflow: unset;
      }
    }
  }
}
