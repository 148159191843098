@import '../../Color.scss';

.my-button-secondary-filled {
  &.ant-dropdown-trigger {
    border-radius: 12px;
    height: auto;
    padding: 11px 18px;
    line-height: 18px;
    box-shadow: none;
    color: $primary-color;
    font-weight: 500;
    &.has_close_icon {
      padding-right: 42px;
    }
    &:hover {
      color: $primary-color !important;
    }
  }
}
