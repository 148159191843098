.account-list-card {
  .ant-card-body {
    padding: 5px !important;
  }
}

.switch-row {
  .ant-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .ant-col {
      width: auto;
      flex-grow: inherit;
      display: flex;
    }
  }
}

.loan-vertical-gap {
  gap: 0px !important;
  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

.account-edit-vertical-gap {
  gap: 0px !important;
}

.account-right-card {
  height: 200px !important;
  width: 26% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .ant-card-body {
    padding: 5px !important;
  }
}
