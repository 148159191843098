.login-card {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  justify-content: center;
  text-align: center;
  width: 450px;
  box-shadow: 0 2px 10px 0 rgba($color: #3a3541, $alpha: 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // height: 500px;
  padding: 11px;
  .ant-card-body {
    width: 100% !important;
  }

  .login-page-remomber-forgot-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
  }

  .my-button,
  .my-input {
    height: 60px;
    max-height: 60px;
    &.input-password {
      .ant-input-clear-icon {
        display: flex;
      }
    }
  }

  &.two_factor_authentication {
    width: auto;
  }

  // .ant-form-item {
  //   background-color: red;
  // }
}
