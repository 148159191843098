@import '../../Color.scss';

.auto-assign-card {
  box-shadow: 0px -10px 10px 0px rgba(63, 88, 118, 0.02) !important;
  .ant-card-body {
    padding: 5px !important;
  }
  .ant-collapse-content {
    padding-block: 0px !important;
  }
  .ant-collapse-content-box {
    padding-block: 0px !important;
  }
  .auto-assign-item {
    background-color: $light-grayish-blue;
    border-radius: 12px;
    height: 45px;
    padding: 10px;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background-color: rgba(136, 112, 230, 0.09) !important;
    }
  }
}

.category-totals-card {
  box-shadow: 0px -10px 10px 0px rgba(63, 88, 118, 0.02) !important;
  .ant-card-body {
    padding: 15px !important;
  }
  .ant-collapse-item {
    padding: 0px !important;
  }
  .ant-collapse-header {
    padding: 0px !important;
  }
  // .ant-collapse-content {
  //   padding-block: 0px !important;
  // }
  .ant-collapse-content-box {
    // padding-block: 0px !important;
    padding: 5px !important;
  }
}

.categories-list-card {
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-card-body {
    padding: 0px !important;
  }
  .ant-collapse-header {
    padding: 10px !important;
  }
  .ant-list-item {
    padding: 0px !important;
  }
  .ant-divider-horizontal {
    margin: 5px 0px !important;
  }
}

.selectedStyle {
  background-color: $primary-color;
  padding: 3px !important;
  span {
    color: $white-color;
  }
  // .ant-statistic-content-value {
  //   color: $white-color;
  //   text-align: center;
  // }
}

.category-child-row {
  width: 39%;
  display: flex;
  justify-content: space-between;
  text-align: center !important;
}

// .category-parent-row {
//   width: 40%;
//   display: flex;
//   justify-content: space-between;
//   text-align: center;
// }

.categories-list-collapse {
  li:last-child {
    .selectedStyle {
      border-radius: 0 0 $bigger-border-radius $bigger-border-radius;
    }
  }
}
