body {
  margin: 0;
  // font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  font-family: 'DM Sans', sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  line-height: normal !important;
  .ant-select-selection-item-content {
    display: flex !important;
  }
  .ant-space-item {
    display: flex;
  }
}

.ant-select-dropdown {
  .ant-select-item-option-content {
    display: flex !important;
    align-items: center !important;
  }
}

/* code {
  font-family: 'DM sans', sans-serif;
} */

.Toastify__toast--error {
  background-color: #e74c3c !important;
}

.Toastify__toast--success {
  background-color: #07bc0c !important;
}

.Toastify__toast--warning {
  background-color: #f0ad4d !important;
}

.Toastify__toast--default {
  background-color: #4dd2f0 !important;
}

.Toastify__toast-body {
  color: white;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 0px !important;
  margin: 0px !important;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 10px;
}
