#root {
  $icon-color: black;

  $icon-color-light: #2e3e5f;

  $icon-color-dark: white;
}

$primary-color: #8870e6;
$success-color: #2cb37a;
$gray-light-color: #f4f6fa;
$gray-light-color-transparent50: rgba(244, 246, 250, 0.5);
$border-radius: 12px;
$bigger-border-radius: 20px;
$white-color: #fff;
$bluish-color: #2e3e5f;
$bluish-color-transparent60: rgba(46, 62, 95, 0.6);
$secondary-text: #a7aab5;
$primary-color-lighter: #b3a0f4;
$primary-color-lighter10: rgba(136, 112, 230, 0.1);
$pale-white: #ffeeeb;
$light-gray: #d3d3d3;
$dark-black-color: #000;
$very-light-gray: #d9d9d9;
$light-grayish-blue: #f8f7fd;
$grayish-blue: #a7abb5;
$outflow-dark-color: #984b82;
$border-color: #f1f1f1;
$focused-border-color: #b19df2;

@mixin bordered($color, $width) {
  border: $width solid $color;
}

// svg {
//   fill: var(--icon-color);
// }

:export {
  iconColorLight: var(--icon-color-light);
  iconColorDark: var(--icon-color-dark);
  secondaryTextColor: $secondary-text;
  successColor: $success-color;
  grayLightColor: $gray-light-color;
  primaryColor: $primary-color;
  borderRadius: $border-radius;
  biggerBorderRadius: $bigger-border-radius;
  whiteColor: $white-color;
  bluishColor: $bluish-color;
  primaryColorLighter: $primary-color-lighter;
  paleWhite: $pale-white;
  lightGray: $light-gray;
  darkBlackColor: $dark-black-color;
  veryLightGray: $very-light-gray;
  lightGrayishBlue: $light-grayish-blue;
  grayishBlue: $grayish-blue;
  outflowDarkColor: $outflow-dark-color;
  borderColor: $border-color;
  focusedBorderColor: $focused-border-color;
}
