@import '../../Color.scss';

.input-date-picker-custom {
  display: flex;
  align-items: center;
  background-color: $white-color;
  border-radius: 20px;
  min-height: 60px;
  padding-left: 20px;
  &.ant-form-item-has-error {
    .ant-picker {
      border-style: none;
    }
    .ant-form-item-row .ant-form-item-control {
      .ant-form-item-control-input + div {
        display: none !important;
      }
    }
  }
  .ant-form-item-label {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-form-item-control-input-content {
    line-height: normal;
    height: 100%;
  }
  .ant-form-item-row {
    flex-direction: row !important;
    align-items: center;
    width: 100%;
    height: 60px;
    .ant-col {
      width: 50% !important;
      height: 100%;
      .ant-select-selector {
        border: none;
      }
    }
  }
  .ant-picker {
    height: 100%;
    border: none;
    &.ant-picker-focused {
      border: none;
      box-shadow: none !important;
    }
  }
}
