.activity-share-wrapper {
  display: flex;
  .share-icon-wrapper {
    margin-left: -15px !important;
    .ant-avatar {
      display: flex !important;
      align-items: center !important;
    }
    .ant-image-img {
      border: 2px solid #f4f6fa !important;
      border-radius: 100% !important;
    }
  }
}

.activity-amount-card {
  box-shadow: 0px -10px 10px 0px rgba(63, 88, 118, 0.02) !important;
  .ant-card-body {
    padding: 17px !important;
  }
}

.stat-number {
  .ant-statistic-content {
    text-align: center !important;
  }
}
