// @import '../../Color.scss';

// .social-login-container {
//   .default-button-first {
//     background: #e1e4ea;
//     color: $secondary-color;
//     box-shadow: none;
//     &:hover {
//       background: #d2d4d8 !important;
//       color: $secondary-color !important;
//       box-shadow: none;
//     }
//   }
// }

.social-button {
  width: 100%;
  background-color: #e1e4ea;
  color: rgba(0, 0, 0, 0.88);
}
