@import '../../Color.scss';

.transaction-divider {
  border-inline-start: 1px solid rgba($color: $dark-black-color, $alpha: 0.1);
  height: 35px;
}

.totals_render_wrapper {
  background-color: $white-color;
  height: 48px;
  border-radius: 14px;
  padding: 10px 15px;
}

.ant-select-dropdown {
  .ant-select-item-option-grouped {
    padding-inline-start: 5px !important;
  }
}

.filter-select-category {
  .ant-select-dropdown {
    .ant-select-item-option-grouped {
      padding-inline-start: 24px !important;
    }
  }
}
