@import '../../../Color.scss';

.select_custom {
  display: flex;
  align-items: center;
  background-color: $white-color;
  border-radius: 20px;
  min-height: 60px;
  padding-left: 20px;
  &.hidden {
    display: none;
  }
  .ant-form-item-label {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-form-item-control-input-content {
    line-height: normal;
    height: 100%;
  }
  &.ant-form-item-has-error {
    .ant-form-item-row .ant-form-item-control {
      .ant-form-item-control-input + div {
        display: none !important;
      }
    }
  }
  &.ant-select-status-success {
    .ant-form-item-row .ant-form-item-control {
      .ant-form-item-control-input + div {
        display: none !important;
      }
    }
  }
  .ant-form-item-row {
    flex-direction: row !important;
    align-items: center;
    width: 100%;
    height: 60px;
    .ant-col {
      width: 50% !important;
      height: 100%;
      .ant-select-selector {
        border: none;
      }
    }
  }
  .ant-select {
    height: 100% !important;
    &.ant-select-focused {
      .ant-select-selector {
        border: none;
        box-shadow: none !important;
      }
    }
    .ant-select-arrow {
      inset-inline-end: 20px;
    }
  }
}
