@import '../../Color.scss';

.my-dropdown-menu {
  padding: 4px;
  width: 270px;
  border-radius: $bigger-border-radius;
  background-clip: padding-box;
  list-style-type: none;
  outline: none;
  // box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
  //   0 9px 28px 8px rgb(0 0 0 / 5%);
  .ant-dropdown-menu-item {
    padding: 5px 12px !important;
    border-radius: 4px !important;
    .my-link {
      font-weight: 400 !important;
    }
  }
  .ant-dropdown-menu-item-group-title {
    padding: 5px 12px !important;
    color: rgba($color: $dark-black-color, $alpha: 0.45);
    transition: all 0.2s;
    font-size: 12px;
    font-weight: 700;
  }
}

.arrow-bg {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow-bg:hover {
  background: rgba($color: $light-gray, $alpha: 0.3);
}

.arrow-bg-always {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba($color: $light-gray, $alpha: 0.3);
}

.dropdown-test {
  width: 90%;
  cursor: pointer;
}

.collapse-icon-parent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
}
